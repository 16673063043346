/* Heebo */

/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 100;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H0TbFhsqMA6aw.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 100;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H2TbFhsqMA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 200;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H0TbFhsqMA6aw.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 200;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H2TbFhsqMA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 300;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H0TbFhsqMA6aw.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 300;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H2TbFhsqMA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H0TbFhsqMA6aw.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H2TbFhsqMA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H0TbFhsqMA6aw.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H2TbFhsqMA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 600;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H0TbFhsqMA6aw.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 600;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H2TbFhsqMA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H0TbFhsqMA6aw.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H2TbFhsqMA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 800;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H0TbFhsqMA6aw.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 800;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H2TbFhsqMA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 900;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H0TbFhsqMA6aw.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 900;
  src: url(/fonts/heebo/v17/NGS6v5_NC0k9P9H2TbFhsqMA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Ballo Bhaijaan */

/* arabic */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAIRsdP3pBmtF8A.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* vietnamese */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAI5sdP3pBmtF8A.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAI9sdP3pBmtF8A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAIFsdP3pBms.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 500;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAIRsdP3pBmtF8A.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* vietnamese */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 500;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAI5sdP3pBmtF8A.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 500;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAI9sdP3pBmtF8A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 500;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAIFsdP3pBms.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 600;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAIRsdP3pBmtF8A.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* vietnamese */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 600;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAI5sdP3pBmtF8A.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 600;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAI9sdP3pBmtF8A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 600;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAIFsdP3pBms.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAIRsdP3pBmtF8A.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* vietnamese */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAI5sdP3pBmtF8A.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAI9sdP3pBmtF8A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAIFsdP3pBms.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 800;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAIRsdP3pBmtF8A.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* vietnamese */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 800;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAI5sdP3pBmtF8A.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 800;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAI9sdP3pBmtF8A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Baloo Bhaijaan 2';
  font-style: normal;
  font-weight: 800;
  src: url(/fonts/baloobhaijaan2/v5/zYX9KUwuEqdVGqM8tPDdAA_Y-_bMAIFsdP3pBms.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
